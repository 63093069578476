import React, { createContext, useContext, useState, useEffect } from 'react'
import { fetchApi } from '@/utils/apiCall'
import useTranslation from 'next-translate/useTranslation'
import { isUserLoggedIn } from '@/utils/utils'

export const WalletContext = createContext()
export const useWallet = () => useContext(WalletContext)

const WalletProvider = ({ children }) => {
  const { lang } = useTranslation('translations-auction')
  const [isWalletCreated, setIsWalletCreated] = useState(false)
  const [walletLoading, setWalletLoading] = useState(true)
  const [walletBalanceInfo, setWalletBalanceInfo] = useState(null)
  const [walletHistoryData, setWalletHistoryData] = useState([])
  const [walletHistoryCurrentPage, setWalletHistoryCurrentPage] = useState(0)
  const [walletHistoryLoading, setWalletHistoryLoading] = useState(true)
  const [walletHistoryTotalCount, setWalletHistoryTotalCount] = useState(Infinity)
  const [walletHistroryFilter, setWalletHistroryFilter] = useState([])
  const [filterCount, setFilterCount] = useState(0)
  const [walleteWithdrawalStatusData, setWalleteWithdrawalStatusData] = useState(null)
  const [sadadInvoicesTotalCount, setSadadInvoicesTotalCount] = useState(0)
  const [sadadInvoicesPendingCount, setSadadInvoicesPendingCount] = useState(0)
  const [listingDetails, setListingDetails] = useState({})
  const [isWithdrawPopupOpen, setIsWithdrawPopupOpen] = useState(false)

  const getWalletBalance = async () => {
    const res = await fetch(`/api/generic?apiUrl=${process.env.NEXT_PUBLIC_GATEWAY_URL}wallet-balance`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        locale: lang,
        Cookie: `i18next=${lang}`,
        authorization: window.localStorage.getItem('authToken') ?? '',
      },
    })
    const response = await res.json()
    setWalletBalanceInfo(response?.data?.data)
  }

  const getWalleteHistoryData = async (page = 0, status = [], CountAfterFilter = 0) => {
    try {
      const filterCountValue = CountAfterFilter !== null ? walletHistroryFilter : []
      const postResponse = await fetchApi({
        lang,
        baseUrl: 'NEXT_PUBLIC_GATEWAY_URL',
        subUrl: `wallet-transaction-history`,
        method: 'POST',
        reqBody: {
          page,
          pageSize: 10,
          status: status.length > 0 ? status : filterCountValue,
        },
      })

      if (!postResponse?.data?.status) {
        setWalletHistoryLoading(false)
        return
      }

      setWalletHistoryCurrentPage(page + 1)
      setWalletHistroryFilter((CountAfterFilter !== 0 && status) || walletHistroryFilter)
      setFilterCount(CountAfterFilter === null ? 0 : CountAfterFilter)

      setWalletHistoryTotalCount(postResponse?.data?.data?.totalCount)
      if (page === 0) {
        setWalletHistoryData(postResponse?.data?.data?.walletTransactionHistory)
      } else {
        setWalletHistoryData([...walletHistoryData, ...postResponse?.data?.data?.walletTransactionHistory])
      }

      setWalletHistoryLoading(false)
    } catch (error) {
      console.log('error')
    }
  }

  const refreshBalanceHistory = async () => {
    await getWalleteHistoryData(0)
    getWalletBalance()
  }

  const getWalleteWithdrawalStatusData = async () => {
    try {
      const responseData = await fetchApi({
        lang,
        baseUrl: 'NEXT_PUBLIC_API_URL_GATEWAY',
        subUrl: `wallet-withdraw-status`,
        method: 'POST',
        reqBody: {
          paymentSourceType: Number(walletBalanceInfo?.paymentSourceType),
          paymentSourceTypeId: Number(walletBalanceInfo?.paymentSourceTypeId),
        },
      })

      setWalleteWithdrawalStatusData(responseData?.data?.data)
      return responseData
    } catch (error) {
      console.error(error)
      return null
    }
  }

  const checkWalletCreated = async () => {
    const res = await fetch(
      `/api/generic?apiUrl=${process.env.NEXT_PUBLIC_API_URL_WALLET_GATEWAY}listing-user-details`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          locale: lang,
          Cookie: `i18next=${lang}`,
          authorization: window.localStorage.getItem('authToken') ?? '',
        },
      },
    )
    const response = await res.json()
    localStorage.setItem('wallet', response?.data?.data?.isWallet)
    if (response?.data?.data?.isWallet !== localStorage.getItem('wallet')) {
      setIsWalletCreated(response?.data?.data?.isWallet)
    }
    setListingDetails(response?.data?.data)
    if (response?.data?.data?.isWallet) {
      getWalletBalance()
    }
    setWalletLoading(false)
  }

  // get invoice list to show view invoice button
  const getSADADInvoiceDataCount = async (status = 'all') => {
    try {
      const response = await fetchApi({
        lang,
        baseUrl: 'NEXT_PUBLIC_API_URL_GATEWAY',
        subUrl: `edaat/invoice-listing`,
        method: 'POST',
        reqBody: { page: 0, pageSize: 1, status },
      })

      if (!response?.data?.status) {
        return
      }
      status === 'all'
        ? setSadadInvoicesTotalCount(response?.data?.data?.totalCount)
        : setSadadInvoicesPendingCount(response?.data?.data?.totalCount)
    } catch {
      status === 'all' ? setSadadInvoicesTotalCount(0) : setSadadInvoicesPendingCount(0)
    }
  }

  useEffect(() => {
    if (isUserLoggedIn()) {
      getSADADInvoiceDataCount()
      getSADADInvoiceDataCount('pending')
    }
  }, [])

  return (
    <WalletContext.Provider
      value={{
        walletBalanceInfo,
        setWalletBalanceInfo,
        walletHistoryData,
        setWalletHistoryData,
        walletHistoryCurrentPage,
        walletHistoryLoading,
        walletHistoryTotalCount,
        getWalleteHistoryData,
        refreshBalanceHistory,
        getWalleteWithdrawalStatusData,
        walleteWithdrawalStatusData,
        isWalletCreated,
        walletLoading,
        getWalletBalance,
        setIsWalletCreated,
        filterCount,
        listingDetails,
        checkWalletCreated,
        isWithdrawPopupOpen,
        setIsWithdrawPopupOpen,
        setWalletHistoryLoading,
        walletHistroryFilter,
        sadadInvoicesTotalCount,
        sadadInvoicesPendingCount,
        getSADADInvoiceDataCount,
      }}
    >
      {children}
    </WalletContext.Provider>
  )
}

export default WalletProvider
